import React, { ChangeEvent, FunctionComponent } from 'react';

import { Checkbox } from '@material-ui/core';
import { isEmpty } from 'lodash';
import DataTable, { Column } from 'application/components/data-table';

import {
  DeviceWithMetadata,
  TowerWithDevicesAndNotes,
  selectShadowDevicesWithStatus,
  selectUnassignedDevicesWithStatus,
} from 'store/selectors/tower.selectors';
import { useSelector } from 'react-redux';
import { ITowerDTO as IInfrastructureTowerDTO } from '@halter-corp/infrastructure-service-client';
import TowersTable from './towers-table';
import {
  batteryVoltageColumn,
  deviceNameColumn,
  enabledColumn,
  statusColumn,
  idColumn,
  indexColumn,
  lastCheckinColumn,
  deviceTowerNameColumn,
  typeColumn,
  towerNameColumn,
  towerDeviceCountColumn,
  vendorColumn,
  selectionColumn,
} from './common-columns';
import TowerNote from '../components/notes/tower-note';
import { DevicesDataRowData } from './devices-data-row';
import DeviceCategoryTable from './device-category-table';

type OverviewTableProps = {
  devices: DeviceWithMetadata[];
  towers: TowerWithDevicesAndNotes[];
  infrastructureTowers: IInfrastructureTowerDTO[];
  selectedDevices: Map<string, DeviceWithMetadata>;
  searchMode: 'farm' | 'shard';
  setSelectedDevices: React.Dispatch<React.SetStateAction<Map<string, DeviceWithMetadata>>>;
  setSelectedTowerForNotes: (selectedTower: TowerWithDevicesAndNotes) => void;
};

const OverviewTable: FunctionComponent<OverviewTableProps> = ({
  devices,
  towers,
  infrastructureTowers,
  setSelectedDevices,
  setSelectedTowerForNotes,
  selectedDevices,
  searchMode,
}) => {
  const shadowDevices: DeviceWithMetadata[] = useSelector(selectShadowDevicesWithStatus);
  const unassignedDevices: DeviceWithMetadata[] = useSelector(selectUnassignedDevicesWithStatus);
  const towersWithInfrastructureNames = towers.map((tower) => {
    const infrastructureTower = infrastructureTowers.find((it) => it.id === tower.id);
    return infrastructureTower ? { ...tower, id: infrastructureTower.name } : tower;
  });

  function handleCheckbox(e: ChangeEvent<HTMLInputElement>) {
    const device = JSON.parse(e.target.value);
    if (e.target.checked) {
      setSelectedDevices((current) => {
        current.set(device.id, device);
        return new Map(current);
      });
    } else {
      setSelectedDevices((current) => {
        current.delete(device.id);
        return new Map(current);
      });
    }
  }

  function handleSelectAllTowerDevices(e: ChangeEvent<HTMLInputElement>) {
    const towerDevices: DeviceWithMetadata[] = JSON.parse(e.target.value);
    if (e.target.checked) {
      setSelectedDevices((current) => {
        towerDevices.forEach((device) => current.set(device.id, device));
        return new Map(current);
      });
    } else {
      setSelectedDevices((current) => {
        towerDevices.forEach((device) => current.delete(device.id));
        return new Map(current);
      });
    }
  }

  function handleSelectAll(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      setSelectedDevices(() => {
        const current = new Map();
        devices.forEach((device) => current.set(device.id, device));
        return new Map(current);
      });
    } else {
      setSelectedDevices(new Map());
    }
  }

  // Device checkbox for shards
  const deviceCheckbox: Column<DeviceWithMetadata> = {
    name: (
      <Checkbox
        checked={!isEmpty(devices) && selectedDevices.size === devices.length}
        onChange={handleSelectAll}
        color="primary"
      />
    ),
    render: (device) => (
      <Checkbox
        color="primary"
        value={JSON.stringify(device)}
        checked={!!selectedDevices.get(device.id)}
        onChange={handleCheckbox}
      />
    ),
    keyExtractor: (device) => device.id.concat('checkbox'),
  };

  if (searchMode === 'shard')
    return (
      <DataTable<DeviceWithMetadata>
        data={devices}
        defaultSortColumnName="Name"
        columns={[
          indexColumn,
          deviceCheckbox,
          idColumn,
          deviceTowerNameColumn,
          deviceNameColumn,
          typeColumn,
          vendorColumn,
          enabledColumn,
          statusColumn,
          selectionColumn,
          lastCheckinColumn,
          batteryVoltageColumn,
        ]}
      />
    );

  // Tower checkbox for farms
  const towerCheckbox: Column<DevicesDataRowData> = {
    name: (
      <Checkbox
        checked={!isEmpty(devices) && selectedDevices.size === devices.length}
        onChange={handleSelectAll}
        color="primary"
      />
    ),
    render: (tower) => (
      <Checkbox
        color="primary"
        value={JSON.stringify(tower.devices)}
        checked={!!tower.devices.length && tower.devices.every((device) => selectedDevices.has(device.id))}
        onChange={handleSelectAllTowerDevices}
        disabled={!tower.devices.length}
      />
    ),
  };

  const towerNotesColumn: Column<DevicesDataRowData> = {
    name: 'Notes',
    keyExtractor: (towerRow) => towerRow.notes?.length,
    render: (towerRow) => (
      <TowerNote notes={towerRow.notes ?? []} onClick={() => setSelectedTowerForNotes(towerRow as any)} />
    ),
    allowCopyToClipboard: true,
  };

  return (
    <>
      <TowersTable
        towers={towersWithInfrastructureNames}
        selectedDevices={selectedDevices}
        onSelectDevice={handleCheckbox}
        columns={[towerCheckbox, towerNameColumn, towerDeviceCountColumn, towerNotesColumn]}
        padding={4}
      />
      <DeviceCategoryTable
        selectedDevices={selectedDevices}
        onSelectDevice={handleCheckbox}
        columns={[towerCheckbox, towerNameColumn, towerDeviceCountColumn]}
        category="shadow devices"
        devices={shadowDevices}
        padding={4}
      />
      <DeviceCategoryTable
        selectedDevices={selectedDevices}
        onSelectDevice={handleCheckbox}
        columns={[towerCheckbox, towerNameColumn, towerDeviceCountColumn]}
        category="unassigned devices"
        devices={unassignedDevices}
        padding={4}
      />
    </>
  );
};

export default OverviewTable;
