import { Marker, Popup } from 'react-leaflet';
import React, { FunctionComponent } from 'react';
import { SettingsInputAntenna, Router, Star, Power } from '@material-ui/icons';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import { Tower } from 'store/selectors/tower.selectors';
import { Box } from '@material-ui/core';

interface TowerMarkerProps {
  tower: Tower;
  towerName: string;
  color?: string;
  existing: boolean;
  modified: boolean;
  hover: string;
  markerRefs: React.MutableRefObject<Map<string, Marker | null>>;
  handleDragEnd: (e: L.DragEndEvent, towerId: string) => void;
  handleRemoveTower: (towerId: string) => void;
  onClose?: () => void;
}
const TowerMarker: FunctionComponent<TowerMarkerProps> = ({
  tower,
  towerName,
  color,
  existing,
  modified,
  hover,
  markerRefs,
  handleDragEnd,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleRemoveTower,
  children,
  onClose,
}) => {
  const borderlessIcon = {
    scale: hover === tower.id ? '1.2' : '1',
    filter: hover === tower.id ? 'drop-shadow( 0px 0px 12px white)' : '',
    strokeWidth: '0%',
  };

  const getColor = () => {
    if (color != null) {
      return color;
    }

    if (existing) {
      if (modified) {
        return 'red';
      }
      return 'white';
    }
    if (modified) {
      return 'orange';
    }
    return 'yellow';
  };

  return (
    <Marker
      draggable={!existing}
      ref={(el) => markerRefs.current.set(tower.id, el)}
      title={towerName}
      ondragend={(e) => {
        if (existing) return;
        handleDragEnd(e, tower.id);
      }}
      icon={L.divIcon({
        className: 'bla',
        html: ReactDOMServer.renderToString(
          <Box>
            <Box display="flex">
              <SettingsInputAntenna
                id={tower.id}
                fontSize="medium"
                // eslint-disable-next-line no-nested-ternary
                htmlColor={getColor()}
                style={borderlessIcon}
              />
              {tower.starlink && (
                <Star
                  id={tower.id}
                  fontSize="small"
                  // eslint-disable-next-line no-nested-ternary
                  htmlColor={getColor()}
                  style={borderlessIcon}
                />
              )}
              {tower.router && (
                <Router
                  id={tower.id}
                  fontSize="small"
                  // eslint-disable-next-line no-nested-ternary
                  htmlColor={getColor()}
                  style={borderlessIcon}
                />
              )}
            </Box>
            {tower.mainsPowered && (
              <Power
                id={tower.id}
                fontSize="small"
                // eslint-disable-next-line no-nested-ternary
                htmlColor={getColor()}
                style={borderlessIcon}
              />
            )}
          </Box>
        ),
      })}
      position={{
        lat: tower.location.latitude,
        lng: tower.location.longitude,
      }}
    >
      {children && (
        <Popup minWidth={200} onClose={onClose}>
          {children}
        </Popup>
      )}
    </Marker>
  );
};

export default TowerMarker;
