import React, { Dispatch, SetStateAction, useState } from 'react';
import { Tower } from 'store/selectors/tower.selectors';
import TowerPopupEditor from './tower-popup-editor';
import TowerMarker from './tower-marker';

interface TowerMarkerWithPopupProps {
  tower: Tower;
  towerName: string;
  color?: string;
  existing: boolean;
  modified: boolean;
  hover: string;
  markerRefs: React.MutableRefObject<Map<any, any>>;
  handleDragEnd: (e: L.DragEndEvent, towerId: string) => void;
  handleCreateTower: (tower: Tower) => Promise<Tower>;
  handleUpdateTower: (tower: Tower) => void;
  handleDeleteTower: (towerId: string) => void;
  handleDeleteTowerFarmMapping?: (towerId: string) => void;
  setTowerMap: Dispatch<SetStateAction<Map<string, Tower>>>;
}
const TowerMarkerWithPopup = ({
  tower,
  towerName,
  color,
  existing,
  modified,
  hover,
  markerRefs,
  handleDragEnd,
  handleCreateTower,
  handleUpdateTower,
  handleDeleteTower,
  handleDeleteTowerFarmMapping,
  setTowerMap,
}: TowerMarkerWithPopupProps) => {
  const [editingState, setEditingState] = useState(!existing);

  const setEditingStateWithBlocking = (value: boolean) => {
    if (existing) setEditingState(value);
  };

  const handleOnClose = () => {
    if (!modified) setEditingStateWithBlocking(false);
  };

  return (
    <TowerMarker
      tower={tower}
      towerName={towerName}
      color={color}
      existing={existing}
      modified={modified}
      hover={hover}
      markerRefs={markerRefs}
      handleDragEnd={handleDragEnd}
      handleRemoveTower={handleDeleteTower}
      onClose={handleOnClose}
    >
      <TowerPopupEditor
        key={`editor-${tower.id}-${tower.mainsPowered}-${tower.router}-${tower.starlink}`}
        tower={tower}
        towerName={towerName}
        editingState={editingState}
        setEditingState={setEditingStateWithBlocking}
        setTowerMap={setTowerMap}
        existing={existing}
        handleCreateTower={handleCreateTower}
        handleRemoveTower={handleDeleteTower}
        handleUpdateTower={handleUpdateTower}
        handleDeleteTowerFarmMapping={handleDeleteTowerFarmMapping}
      />
    </TowerMarker>
  );
};

export default TowerMarkerWithPopup;
