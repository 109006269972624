import React, { FunctionComponent, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet';
import L, { LeafletMouseEvent } from 'leaflet';
import styled from 'styled-components';

import FarmSelect from 'application/components/farm-select';
import TopographyService from 'services/topography.service';
import { uniqueId } from 'lodash';

import { InfoTwoTone } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ITowerLocationDTO } from '@halter-corp/tower-service-client';
import { Tower } from 'store/selectors/tower.selectors';
import { useSelector } from 'react-redux';
import { selectLatLngTowers as selectLatLngInfrastructureTowers } from 'store/selectors/infrastructure.selectors';
import { MapUtils } from 'application/utils/MapUtils';
import TowersDrawer from '../components/towers-drawer';
import TowerInstallTooltip from '../components/tooltips/tower-install-tooltip';
import { useNewTowersContext } from '../contexts/new-tower-context';
import { useExistingTowersContext } from '../contexts/existing-tower-context';
import { DEFAULT_MAP_CENTER } from '../constants';
import TowerMarkerWithPopup from '../components/tower-marker-with-popup';
import { useModifiedTowersContext } from '../contexts/modified-tower-context';
import { useExternalTowersContext } from '../contexts/external-tower-context';
import ExternalTowerMarkerWithPopup from '../components/external-tower-marker-with-popup';
import WarningMessage from '../components/message/WarningMessage';

const ScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 44px);
  width: 100vw;
  overflow: scroll;
`;
const ContentWrapper = styled.div`
  position: absolute;
  display: flex;
  width: auto;
  top: 60px;
  left: 48px;
  flex-direction: column;
  gap: 8px;
  justify-content: space-evenly;
`;
const FarmSelectWrapper = styled.div`
  width: 256px;
  background-color: #fff;
  border-radius: 8px;
  padding: 4px;
  margin-left: 4px;
  z-index: 9999;
`;
const WarningWrapper = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  top: 60px;
  left: 48px;
  padding: 4px;
  z-index: 9999;
`;

const MapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
`;

type InstallPlanScreenProps = {
  farmId: string;
  markerRefs: React.MutableRefObject<Map<any, any>>;
  warningMessage: { open: boolean; content: string };
  setWarningMessage: React.Dispatch<React.SetStateAction<{ open: boolean; content: string }>>;
  handleSaveTower: (tower: Tower) => Promise<any>;
  handleUpdateTower: (tower: Tower) => void;
  handleRemoveTower: (towerId: string) => void;
  handleDeleteTower: (towerId: string) => void;
  handleCreateTowerFarmMapping: (towerId: string) => void;
  handleDeleteTowerFarmMapping: (towerId: string) => void;
};

const InstallPlanScreen: FunctionComponent<InstallPlanScreenProps> = ({
  farmId,
  markerRefs,
  warningMessage,
  setWarningMessage,
  handleSaveTower,
  handleUpdateTower,
  handleRemoveTower,
  handleDeleteTower,
  handleCreateTowerFarmMapping,
  handleDeleteTowerFarmMapping,
}) => {
  const history = useHistory();
  const mapRef = useRef<LeafletMap>(null);
  const metadata = TopographyService.useFarmMapMetadata(farmId);
  const { existingTowerMap, setExistingTowerMap } = useExistingTowersContext();
  const { externalTowerMap } = useExternalTowersContext();
  const { newTowerMap, setNewTowerMap } = useNewTowersContext();
  const { modified } = useModifiedTowersContext();
  const [hover, setHover] = useState('');
  const infrastructureTowers = useSelector(selectLatLngInfrastructureTowers);
  const infrastructureTowersById = MapUtils.keyBy(infrastructureTowers, (it) => it.id);

  const handleMoveToTower = (latlng: L.LatLng, instant: boolean = false) => {
    if (mapRef.current == null) {
      return;
    }
    if (instant) {
      mapRef.current.leafletElement.setView(latlng, 18);
    } else {
      mapRef.current.leafletElement.flyTo(latlng, 18);
    }
  };

  const handleFarmChanged = (newFarmId: string) => {
    const location = { search: new URLSearchParams({ farmId: newFarmId }).toString() };
    history.push(location);
  };

  const handleDragEnd = (e: L.DragEndEvent, towerId: string) => {
    setNewTowerMap((current) => {
      const currentTower = current.get(towerId);
      const draggedMarker = markerRefs.current.get(towerId) as Marker;
      if (currentTower != null && draggedMarker != null) {
        const { lat, lng } = draggedMarker.leafletElement.getLatLng();
        const newLocation = {
          latitude: lat,
          longitude: lng,
        } as ITowerLocationDTO;
        currentTower.location = newLocation;
        current.set(towerId, currentTower);
      }
      return new Map(current);
    });
  };

  const handleContextMenu = (e: LeafletMouseEvent) => {
    setNewTowerMap((currentState) => {
      const newTower = {
        id: `new-tower-${uniqueId()}`,
        farmId,
        networkGroupId: '',
        location: {
          longitude: e.latlng.lng,
          latitude: e.latlng.lat,
        } as ITowerLocationDTO,
        notes: [],
        mainsPowered: false,
        router: false,
        starlink: false,
      };
      currentState.set(newTower.id, newTower);
      return new Map(currentState);
    });
  };

  return (
    <ScreenWrapper>
      <TowersDrawer
        handleRemoveTower={(tower: Tower) => handleRemoveTower(tower.id)}
        setHover={setHover}
        setWarningMessage={setWarningMessage}
        moveTo={handleMoveToTower}
        handleDeleteTower={handleDeleteTower}
        handleSaveTower={handleSaveTower}
        handleUpdateTower={handleUpdateTower}
      />
      <ContentWrapper>
        {metadata == null && (
          <WarningWrapper>
            <Alert severity="warning">Warning: No location data for selected farm</Alert>
          </WarningWrapper>
        )}
        <FarmSelectWrapper>
          <FarmSelect onChange={handleFarmChanged} />
        </FarmSelectWrapper>
      </ContentWrapper>
      <MapWrapper>
        <LeafletMap
          ref={mapRef}
          preferCanvas
          bounds={metadata?.bounds ?? DEFAULT_MAP_CENTER}
          minZoom={13}
          maxZoom={22}
          style={{ flex: 1 }}
          oncontextmenu={handleContextMenu}
        >
          <TileLayer url="https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}" />
          {metadata && <TileLayer url={metadata?.tileUrl} />}
          {[...newTowerMap.values()].map((tower) => (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <TowerMarkerWithPopup
              key={`new-${tower.id}`}
              tower={tower}
              towerName={infrastructureTowersById.get(tower.id)?.name ?? tower.id}
              hover={hover}
              existing={false}
              modified={modified.get(tower.id) ?? false}
              markerRefs={markerRefs}
              setTowerMap={setNewTowerMap}
              handleDragEnd={handleDragEnd}
              handleCreateTower={handleSaveTower}
              handleUpdateTower={handleUpdateTower}
              handleDeleteTower={handleRemoveTower}
            />
          ))}
          {[...existingTowerMap.values()].map((tower) => {
            if (tower.farmId === farmId) {
              return (
                // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
                <TowerMarkerWithPopup
                  key={`existing-${tower.id}`}
                  tower={tower}
                  towerName={infrastructureTowersById.get(tower.id)?.name ?? tower.id}
                  hover={hover}
                  existing
                  modified={modified.get(tower.id) ?? false}
                  markerRefs={markerRefs}
                  setTowerMap={setExistingTowerMap}
                  handleDragEnd={handleDragEnd}
                  handleCreateTower={handleSaveTower}
                  handleUpdateTower={handleUpdateTower}
                  handleDeleteTower={handleDeleteTower}
                />
              );
            }
            return (
              // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
              <TowerMarkerWithPopup
                key={`existing-${tower.id}`}
                tower={tower}
                towerName={infrastructureTowersById.get(tower.id)?.name ?? tower.id}
                hover={hover}
                color="darkturquoise"
                existing
                modified={modified.get(tower.id) ?? false}
                markerRefs={markerRefs}
                setTowerMap={setExistingTowerMap}
                handleDragEnd={handleDragEnd}
                handleCreateTower={handleSaveTower}
                handleUpdateTower={handleUpdateTower}
                handleDeleteTower={handleDeleteTower}
                handleDeleteTowerFarmMapping={handleDeleteTowerFarmMapping}
              />
            );
          })}
          {[...externalTowerMap.values()].map((tower) => (
            // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
            <ExternalTowerMarkerWithPopup
              key={`existing-${tower.id}`}
              tower={tower}
              towerName={infrastructureTowersById.get(tower.id)?.name ?? tower.id}
              farmId={farmId}
              color="dodgerblue"
              hover={hover}
              existing
              modified={modified.get(tower.id) ?? false}
              markerRefs={markerRefs}
              handleDragEnd={handleDragEnd}
              handleRemoveTower={handleDeleteTower}
              handleCreateTowerFarmMapping={handleCreateTowerFarmMapping}
            />
          ))}
        </LeafletMap>
      </MapWrapper>
      <Tooltip placement="top-end" title={<TowerInstallTooltip />}>
        <InfoTwoTone
          htmlColor="#FFFFFF"
          style={{
            stroke: '#111111',
            strokeWidth: '2%',
            position: 'fixed',
            left: '20px',
            bottom: '20px',
            zIndex: 9999,
            fontSize: '40px',
          }}
        />
      </Tooltip>
      <WarningMessage
        open={warningMessage.open}
        content={warningMessage.content}
        onClose={() => setWarningMessage((current) => ({ ...current, open: false }))}
      />
    </ScreenWrapper>
  );
};

export default InstallPlanScreen;
